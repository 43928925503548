import React, { useState, useEffect } from 'react';
import {Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import {AxiosSyncDefaultRequest} from "../../../../components/default_request";
import server from "../../../../server";
import {useDispatch} from "react-redux";
import {RenderSelectField} from "../../../../components/renderField";
import {state}  from "./module";

const EditFormDialog = ({ open, onClose, rowData, onSave }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        warehouse_number: '',
        name: '',
        IATA: '',
        address1: '',
        address2: '',
        city: '',
        postal_code: '',
        state: '',
        phone: '0000000000',
        company_name: 'Shipping dept',
        country: 'US',
        nameToChinese: "美东",
        is_specific: false,
        address_type: 1,
    });
    const [statusFormData, setStatusFormData] = useState({
        warehouse_number: {required: true, message: '', status: false},
        IATA: {required: true, message: '', status: false},
        name: {required: true, message: '', status: false},
        address1: {required: true, message: '', status: false},
        city: {required: true, message: '', status: false},
        postal_code: {required: true, message: '', status: false},
        state: {required: true, message: '', status: false},
        phone: {required: true, message: '', status: false},
        company_name: {required: true, message: '', status: false},
        country: {required: true, message: '', status: false},
        nameToChinese: {required: true, message: '', status: false},
        is_specific: {required: true, message: '', status: false},
        address_type: {required: true, message: '', status: false},
    })

    // const [user_options, setUserOptions] = useState([])
    const [warehouse_info, setWarehouseInfo] = useState({})
    const dispatch = useDispatch();
    // 当rowData改变时，更新表单数据
    useEffect(() => {
        if (rowData) {
            setLoading(true)
            let row_data = {}
            Object.entries(rowData).forEach(([key, value]) => {
                    // if (key === "warehouse_number"){
                    //     setWarehouseNumber(value)
                    // }
                    row_data[key] = value

            })
            setFormData(row_data)
            setLoading(false)
        }
    }, [rowData]);

    const handleChange = (event) => {
        const { name, value } = event.target;

            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));

    };
    const handleFieldChange = (event, value, key, value_key) => {
            setFormData((prev) => ({
                ...prev,
                [key]: value?value[value_key]:'',
            }));

    };
    const fetchWareData = async () => {
        try {
            setLoading(false)
            let maxNumber = 0
            await AxiosSyncDefaultRequest({
                url: server.warehouse_list_url,
                method: 'GET',
                success: (res) => {
                    let options =  {warehouse_number: [], name: []}
                    let rows = res.data.rows
                    for (const i in rows) {
                        options.warehouse_number.push(rows[i].warehouse_number)
                        options.name.push(rows[i].name)
                        if(rows[i].warehouse_number > maxNumber) {
                            maxNumber = rows[i].warehouse_number
                        }
                    }
                    setWarehouseInfo(options)
                    setLoading(false)
                },
                // fail: (error) => {
                //     console.error('请求失败', error);
                //     setData([])
                //     setLoading(false)
                // }
            });
        } catch (error) {
            setLoading(false)
            console.error('请求异常', error);
        }
    };
    useEffect(() => {
        setLoading(true)
        const fetchData = async () => {
            try {
                // await AxiosSyncDefaultRequest({
                //     url: `${server.user_url}?page=0&size=100`,
                //     method: 'GET',
                //     success: (res) => {
                //         let options = []
                //         let rows = res.data.rows
                //         for (const i in rows) {
                //             options.push({id: rows[i].id, name: rows[i].username})
                //         }
                //         setUserOptions(options)
                //     },
                //     // fail: (error) => {
                //     //     console.error('请求失败', error);
                //     //     setData([])
                //     //     setLoading(false)
                //     // }
                // });
            } catch (error) {
                console.error('请求异常', error);
            }
        };

        fetchWareData();
        // fetchData();
    }, [dispatch]);
    const validateZipCode = (zipCode) => {
        // 正则表达式匹配五位数字或五位数字-四位数字格式
        const zipCodeRegex = /^\d{5}(-\d{4})?$/;
        return zipCodeRegex.test(zipCode);
    }

    const isPositiveIntegerWithLimit = (str) => {
        const regex = /^[1-9]\d{0,9}$/;
        return regex.test(str);
    }
    const handleSave = async () => {
        // let data = formData
        // Object.entries(formData).forEach(([key, value]) => {
        //     if (value === '') {
        //         delete data[key]
        //     }
        // });

        // 创建一个新的对象来存储错误
        const newErrors = {};
        let is_valid = true;

        // 检查所有必填字段是否已填写
        for (let field in statusFormData) {
            if (field === "is_specific"){
                if(typeof formData[field] !== 'boolean'){
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: 'This field is required',
                        status: true
                    };
                    is_valid = false;
                }
                else {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: '',
                        status: false
                    };
                }

            }
            else {
                if (statusFormData[field].required && !formData[field]) {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: 'This field is required',
                        status: true
                    };
                    is_valid = false;
                } else {
                    newErrors[field] = {
                        ...statusFormData[field],
                        message: '',
                        status: false
                    };
                }
            }
        }
        // 如果有错误，更新状态并停止保存操作
        setStatusFormData({
            ...statusFormData,
            ...newErrors
        });
        if (!is_valid) {
            return;
        }


        let save = true
        if (!validateZipCode(formData.postal_code)) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                postal_code: {required: true, message: "请输入正确的邮编！", status: true}
            }));
        }
        if (!isPositiveIntegerWithLimit(formData.warehouse_number)) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                warehouse_number: {required: true, message: "仓库编号只支持正整数！", status: true}
            }));
        }
        // 如果仓库编号已经存在并且不是原来的仓库编号不允许修改
        if (warehouse_info.warehouse_number.includes(parseInt(formData.warehouse_number)) && parseInt(formData.warehouse_number) !== rowData.warehouse_number) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                warehouse_number: {required: true, message: "仓库编号已经存在，请更改！", status: true}
            }));
        }
        if( warehouse_info.name.includes(formData.name) && rowData.name !== formData.name) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                name: {required: true, message: "仓库名已经存在，请更改！", status: true}
            }));
        }
        if (formData.IATA.length < 3) {
            save = false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                IATA: {required: true, message: "IATA长度为3-4位！", status: true}
            }));
        }

        if (formData.country.length < 2 || formData.country.length > 3) {
            save=false
            setStatusFormData(prevPerson => ({
                ...prevPerson,
                country: {required: true, message: "country长度为2-3位！", status: true}
            }));

        }
        // 以上如果不满足规则，则不请求
        if (!save) {
            return
        }
        try {
            setLoading(true);
            let new_data = JSON.parse(JSON.stringify(formData));
            if(new_data.user === "" || new_data.user === undefined) {
                new_data.user = null
            }
            const result = await onSave(new_data, formData.id, 'PUT');
            if (result.status === true) {
                fetchWareData()
                // 操作成功，显示成功消息
                // setSnackbar({ open: true, message: '保存成功！', severity: 'success' });
            }
        } catch (error) {
            console.error("保存失败", error);
            // 操作失败，显示错误消息
            // setSnackbar({ open: true, message: '保存失败，请重试。', severity: 'error' });
        } finally {
            setLoading(false);
            onClose(); // 保存操作完成后关闭对话框
        }
    };
    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle>编辑信息</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="warehouse_number"
                                label="仓库编号"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={formData.warehouse_number}
                                onChange={handleChange}
                                required={statusFormData.warehouse_number.required}
                                helperText={statusFormData.warehouse_number.message}
                                error={statusFormData.warehouse_number.status}
                            >
                            </TextField>
                        </Grid>
                        {/*<Grid item xs={12} md={8}>*/}

                        {/*    <RenderSelectField*/}
                        {/*        margin="dense"*/}
                        {/*        idAname="user"*/}
                        {/*        label="用户"*/}
                        {/*        value={formData.user}*/}
                        {/*        select_options={user_options}*/}
                        {/*        v="id"*/}
                        {/*        value_name="name"*/}
                        {/*        onChangeHandler={(e, value) => handleFieldChange(e, value, "user", "id")}*/}
                        {/*        sx={{width: 353}}*/}
                        {/*        required={statusFormData.user.required}*/}
                        {/*        error={statusFormData.user.status}*/}
                        {/*        helperText={statusFormData.user.message}*/}
                            />
                            {/*    <MenuItem aria-label="None" key={'Warehouse_None'} value={""}>不选择</MenuItem>*/}

                            {/*    {user_options.map((option) => (*/}
                            {/*        <MenuItem key={option.id} value={option.id}>*/}
                            {/*            {option.name}*/}
                            {/*        </MenuItem>*/}
                            {/*    ))}*/}
                            {/*</RenderSelectField>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="name"
                                label="仓库名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.name}
                                onChange={handleChange}
                                required={statusFormData.name.required}
                                error={statusFormData.name.status}
                                helperText={statusFormData.name.message}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="IATA"
                                label="IATA"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.IATA}
                                onChange={handleChange}
                                inputProps={{
                                    minLength: 3,
                                    maxLength: 4
                                }}
                                required={statusFormData.IATA.required}
                                helperText={statusFormData.IATA.message}
                                error={statusFormData.IATA.status}
                            >
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address1"
                                label="地址一"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.address1}
                                onChange={handleChange}
                                inputProps={{
                                    maxLength: 30
                                }}
                                required={statusFormData.address1.required}
                                error={statusFormData.address1.status}
                                helperText={statusFormData.address1.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address2"
                                label="地址二"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.address2}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="city"
                                label="城市"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.city}
                                onChange={handleChange}
                                required={statusFormData.city.required}
                                error={statusFormData.city.status}
                                helperText={statusFormData.city.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="postal_code"
                                label="邮编"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.postal_code}
                                onChange={handleChange}
                                inputProps={{
                                    maxLength: 10
                                }}
                                required={statusFormData.postal_code.required}
                                error={statusFormData.postal_code.status}
                                helperText={statusFormData.postal_code.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <RenderSelectField
                                idAname="state"
                                select_options={state}
                                value={formData.state}
                                label="州"
                                v={"abbreviation"}
                                value_name={"abbreviation"}
                                onChangeHandler={(e, v) => handleFieldChange(e, v, "state", "abbreviation")}
                                required={statusFormData.state.required}
                                error={statusFormData.state.status}
                                helperText={statusFormData.state.message}
                                sx={{width: 353}}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="phone"
                                label="电话"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.phone}
                                onChange={handleChange}
                                inputProps={{
                                    maxLength: 14  // 最大长度，包括格式字符
                                }}
                                required={statusFormData.phone.required}
                                error={statusFormData.phone.status}
                                helperText={statusFormData.phone.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="company_name"
                                label="公司名"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.company_name}
                                onChange={handleChange}
                                required={statusFormData.company_name.required}
                                error={statusFormData.company_name.status}
                                helperText={statusFormData.company_name.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="country"
                                label="国家/地区"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={formData.country}
                                onChange={handleChange}
                                required={statusFormData.country.required}
                                error={statusFormData.country.status}
                                helperText={statusFormData.country.message}
                                inputProps={{
                                    maxLength: 3
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="nameToChinese"
                                label="中文名"
                                type="nameToChinese"
                                fullWidth
                                variant="standard"
                                value={formData.nameToChinese}
                                onChange={handleChange}
                                required={statusFormData.nameToChinese.required}
                                error={statusFormData.nameToChinese.status}
                                helperText={statusFormData.nameToChinese.message}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="is_specific"
                                label="是否退回美西"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.is_specific}
                                onChange={handleChange}
                                required={statusFormData.is_specific.required}
                                error={statusFormData.is_specific.status}
                                helperText={statusFormData.is_specific.message}
                            >
                                <MenuItem key="is_specific_false" value={false}>
                                    是
                                </MenuItem>
                                <MenuItem key="is_specific_true" value={true}>
                                    否
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                margin="dense"
                                name="address_type"
                                label="address_type"
                                type="text"
                                fullWidth
                                select
                                variant="standard"
                                value={formData.address_type}
                                onChange={handleChange}
                                required={statusFormData.address_type.required}
                                error={statusFormData.address_type.status}
                                helperText={statusFormData.address_type.message}
                            >
                                <MenuItem key="address_type_1" value={1}>
                                    只允许正向物流
                                </MenuItem>
                                <MenuItem key="address_type_2" value={2}>
                                    支持正逆向物流
                                </MenuItem>
                                <MenuItem key="address_type_3" value={3}>
                                    只允许逆向物流
                                </MenuItem>
                            </TextField>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>取消</Button>
                    <LoadingButton loading={loading} onClick={handleSave} variant={"contained"}>保存</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default EditFormDialog;
