import * as React from 'react';
import {connect} from "react-redux";
import {loadShipment, previewShipmentFile} from "../redux/actions/shipment";

import store from "../redux/store";
import {SHIPMENT_CLOSE_ALERT, SHIPMENT_ORDERS, SHIPMENT_STOP} from "../redux/type";
import {DataGridPro, GridColumnOrderChangeParams} from "@mui/x-data-grid-pro";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";
import Box from "@mui/material/Box";
import SearchTable from "../components/searchTable";
import APIToolBar from "../components/APIToolBar";
import Grid from "@mui/material/Grid";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import './styles.scss'
import StyledBox from "../components/StyledBox";
import SetColumns from "../components/SetColumns";
import SetVisibility from "../components/SetVisibility";
import Button from "@mui/material/Button";
import PackageDisplay from "./modal/PackageDisplay";
import {GridActionsCellItem} from "@mui/x-data-grid";
import PreviewIcon from '@mui/icons-material/Preview';
import PreViewModal from "./modal/PreViewModal"
import APICircularProgress from "../components/APICircularProgress";
import APIAlert from "../components/APIAlert";
import {AxiosDefaultRequest} from "../components/default_request";
import server from "../server";
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import Tooltip from "@mui/material/Tooltip";
import RateDetails from "./modal/rateDetails";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import Alert from "@mui/material/Alert";
import {Snackbar} from "@mui/material";
import {useState} from "react";
import ClipboardHandler from "../components/ClipboardHandler";

/*
field
headerName
hide
minWidth
width
description
flex 弹性分配
valueGetter
valueFormatter
getActions
headerClassName
cellClassName
*/

const status_dict = {
    '-1': 'Unknown',
    '1': 'Processing',
    '2': 'Finish',
    '3': 'Ordered',
    '4': 'Error',
    '5': 'UnknownError',
    '6': 'Void'
};
const status_chinese = {
    "-1": "未知", "1": "处理中", "2": "已出单", "3": "完成", "4": "错误", "5": "未知错误", "6": "已取消"
}
const warehouse_options = {};
const service_dict = {}


class Shipment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Visibility: this.setVisibility,
            traceOpen: false,
            warehouseOptions: {},
            address_type_map: {
                "-1": "未验证",
                "0": "商业",
                "1": "住宅",
                "2": "混合",
                "3": "未知",
            },
            preViewOpen: false,
            rateDetailsOpen: {}, // 存储每个RateDetails的开关状态
            track: null,
            service_dict: {},
            package_id_dict: {},
        }
        this.trace = React.createRef();
    }

    localStorageKey = this.props.user.username + "_" + 'shipmentColumns';
    localStorageVisibilityKey = this.props.user.username + "_" + 'shipmentVisibility';
    setRateDetails = (isOpen) => {
        this.setState({rateDetailsOpen: isOpen})
    }
    handle_open = (is_open) => {
        this.state.open = is_open
    }
    get_package_id = (id) => {
        return AxiosDefaultRequest({
            url: `${server.package_id}?shipment=${id}`,
            method: "GET",
            success: (res) => {
                this.setState({...this.state, package_id_dict: {...this.state.package_id_dict, [id]: res.data.results[0].packageid
                    }})
            },
            fail: (error) => {

                // throw error
            }
        })
    }
    service_format = (number) => {
        let description =  this.state.service_dict[number.value]
        if (description){
            return description
        }
        return ''
    }
    bill_status_format = (status) => {
        return status.value?"是":"否"
    }
    tracking_number_format = (track, ) => {
        // track.row.status
        if (track.row.status === '2' || track.row.status === '3' || track.row.status === '6') {
            // let [mrg, setMrg] = useState("复制到粘贴板")
            return (
                <>
                    <PackageDisplay trace={track.value} trace_id={track.id}/>
                        {/*<Tooltip title={mrg}>*/}
                        {/*    <Button aria-describedby={track.id} startIcon={<CopyAllIcon/>} style={{ color: 'rgba(0, 0, 0, 0.30)' }} onClick={(e) => navigator.clipboard.writeText(track.value).then(() => {*/}
                        {/*        setMrg("√ 复制成功")*/}
                        {/*    }).catch(err => {*/}
                        {/*        setMrg("复制失败")*/}
                        {/*    })} onMouseOver={*/}
                        {/*        (e) => {setMrg("复制到粘贴板")}*/}
                        {/*    }/>*/}

                        {/*</Tooltip>*/}
                    <ClipboardHandler track={track}/>
                </>
            )
        }
        return <Button>{track.value}</Button>
    }
    warehouse_format = (params) => {
        return this.state.warehouseOptions[params.value] || '';
    }
    address_map_format = (params) => {
        return this.state.address_type_map[params.value] || '';
    }

    toggleRateDetails = (id) => {
        // 切换指定行的展开状态
        this.setState(prevState => ({
            rateDetailsOpen: {
                ...prevState.rateDetailsOpen,
                [id]: !prevState.rateDetailsOpen[id]
            }
        }));
    };
    package_id_format = (params) => {
        if(!this.state.package_id_dict[params.row.id]){
            return (<Button onClick={() => this.get_package_id(params.row.id)}>获取</Button>)
        }
        else {
            return this.state.package_id_dict[params.row.id]
        }

    }

    columns: GridColDef[] = [
        {id: 1, field: "id", headerName: "ID"},
        {
            id: 2,
            field: "tracking_number",
            headerName: "TrackingNumber",
            width: 251,
            renderCell: this.tracking_number_format
        },
        // type: 'singleSelect', valueOptions: ['-1', '1', '2', '3', '4', '5', '6']
        {
            id: 3, field: "status", headerName: "Status", resizable: false, width: 90, renderCell: (params) => {
                let status = status_dict[params.row.status]
                let chinese_status = status_chinese[params.row.status]
                return (
                    <div className={`cellWithStatus ${status}`}>
                        {chinese_status}
                    </div>
                )
            }
        },
        {id: 37,field: '', headerName: "package_id", renderCell: this.package_id_format},
        {id: 21, field: "user", headerName: "Account"},
        {id: 22, field: "UID", headerName: "UID", width: 100},
        {id: 4, field: "create_time", headerName: "Create time", width: 175},
        {id: 5, field: "Logistic_name", headerName: "Supplier", width: 80},
        {id: 6, field: "Warehouse", headerName: "Warehouse", width: 150, valueFormatter: this.warehouse_format},
        {id: 34, field: "address_type", headerName: "address type", width: 100, valueFormatter: this.address_map_format},
        {id: 7, field: "Address1", headerName: "Address1", width: 200},
        {id: 33, field: "Address2", headerName: "Address2", width: 200},
        {id: 8, field: "City", headerName: "City", width: 200},
        {id: 9, field: "PhoneNumber", headerName: "Phone", width: 150},
        {id: 10, field: "PostalCode", headerName: "PostalCode"},
        {id: 11, field: "Country", headerName: "Country"},
        {id: 12, field: "RecipientName", headerName: "ShipToName"},
        {id: 13, field: "StateOrProvinceCode", headerName: "State", width: 50},
        {id: 24, field: "ServiceNumber", headerName: "Service", width: 150, valueFormatter: this.service_format},
        {id: 14, field: "Reference1", headerName: "Reference"},
        {id: 15, field: "Rate", headerName: "Prepaid$"},
        {id: 16, field: "bill_fee", headerName: "Billed$"},
        {
            id: 27,
            field: "total_adj",
            headerName: "TotalAdj$",
            valueGetter: (params) => {
                // 确保Rate和bill_fee字段存在并且是数字类型
                const prepaid = params.row.Rate ?? 0;
                const billed = params.row.bill_fee ?? 0;
                return (billed - prepaid).toFixed(2); // 返回计算结果，并保留两位小数
            }
        },
        {id: 17, field: "MultiPackages", headerName: "Amount"},
        {id: 18, field: "bill_status", headerName: "Bill Status", valueFormatter: this.bill_status_format},
        {id: 19, field: "all_track", headerName: "All Trace", width: 100},
        {id: 20, field: "path", headerName: "download name", width: 100},
        {id: 23, field: "error_msg", headerName: "error msg", width: 100},
        {id: 25, field: "second_tracking_number", headerName: "USPS Tracking Number"},
        {id: 36, field: "account", headerName: "account"},
        {
            id: 26, type: 'actions', field: 'actions', headerName: "Actions", getActions: (params) => {
                return [
                    <PreViewModal
                        key={`preview-${params.row.id}`}
                        ship_id={params.row.id}
                        disabled={params.row.status !== '2' && params.row.status !== '3'}
                    />,
                    <RateDetails
                        key={`rate-details-${params.row.id}`}
                        isOpen={this.state.rateDetailsOpen[params.row.id] || false}
                        setOpen={() => this.toggleRateDetails(params.row.id)}
                        details={params.row.rate_details}
                        disabled={false}
                    />
                ]
            }
        },

        // next 37
    ]
    // 控制是否展示列
    columnVisibilityModel = {
        id: false,
        path: false
    }
    set_columns = SetColumns(this.localStorageKey, this.columns);
    setVisibility = SetVisibility(this.localStorageVisibilityKey, this.columnVisibilityModel);

    ColumnOrderChange = (e: GridColumnOrderChangeParams) => {
        // 获取columns
        let old = this.set_columns[e.oldIndex]
        let target = this.set_columns[e.targetIndex]
        this.set_columns[e.targetIndex] = old
        this.set_columns[e.oldIndex] = target
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.set_columns))
        // oldIndex > targetIndex
        // targetIndex < oldIndex
    }

    useQuery = (page) => {
        this.props.loadShipment((page * this.props.pageSize), ((page + 1) * this.props.pageSize), page,
            null, store.getState().shipment.search, null, null)
    }
    changePageSize = (pageSize) => {
        setTimeout(() => {
            this.props.loadShipment((this.props.page * pageSize), ((this.props.page + 1) * pageSize),
                null, pageSize, store.getState().shipment.search, null, null)
        }, 100);
    }

    componentDidMount() {
        // 预加载操作
        this.props.loadShipment(this.props.page, this.props.pageSize, null,
            null, store.getState().shipment.search, null, null);

        AxiosDefaultRequest({
            url: server.warehouse_list_url,
            success: res => {
                for (const i in res.data.rows) {
                    warehouse_options[res.data.rows[i].warehouse_number] = res.data.rows[i].name;
                }
                this.setState({warehouseOptions: warehouse_options});
            }
        });
        AxiosDefaultRequest({
            url: `${server.select_logistics_service_list}?service_type=CreateShipment&service_type=ReturnShipment`,
            success: res => {
                for (const i in res.data.rows) {
                    service_dict[res.data.rows[i].service_number] = res.data.rows[i].description;
                }
                this.setState({service_dict: service_dict});
            }
        });
    }

    componentDidUpdate(prevProps) {
        // search
        if (store.getState().shipment.stop && this.props.search && !this.props.isLoading && this.props.searchClick) {
            store.dispatch({ type: SHIPMENT_STOP });
            this.props.loadShipment(this.props.page, this.props.pageSize, null, null, store.getState().shipment.search, null, null);
        }
        // order
        if (this.props.orders && this.props.orderClick && this.props.orders !== prevProps.orders) {
            store.dispatch({ type: SHIPMENT_STOP });
            this.props.loadShipment(this.props.page, this.props.pageSize, null, null, store.getState().shipment.search, null, this.props.orders);
        }
    }

    ChangeColumnVisibilityModel = (modal) => {
        localStorage.setItem(this.localStorageVisibilityKey, JSON.stringify(modal))
        this.setState({Visibility: modal})
    }
    ChangeColumnSortModel = (modal) => {
        // 触发 UNSAFE_componentWillReceiveProps
        store.dispatch({type: SHIPMENT_ORDERS, payload: modal})
    }
    closeAlert = () => {
        store.dispatch({type: SHIPMENT_CLOSE_ALERT})
    }
    render() {
        let loading;
        let alert;

        if (this.props.isLoading || this.props.ModalLoading) {
            loading = APICircularProgress('success')
        }
        if (this.props.alert && this.props.payload) {
            const {level, title, message} = this.props.payload
            const alert_p = {level: level, title: title, message: message, options: null, closeFunc: this.closeAlert}
            alert = APIAlert(alert_p)
        }
        return (
            <Box sx={{
                width: '100%'
            }}>
                {loading}{alert}
                <Grid container>
                    <Grid container item md={12} lg={12}>
                        <Box sx={{minWidth: '100%'}}>
                            <SearchTable user={this.props.user} with_holding={this.props.with_holding} refund={this.props.refund} lump_sum={this.props.lump_sum}/>
                        </Box>
                    </Grid>
                    <Grid item md={12} lg={12}>
                        <StyledBox>
                            {this.props.data ? <DataGridPro
                                rows={this.props.data}
                                density="compact"
                                columns={this.set_columns}
                                pageSize={this.props.pageSize}
                                rowsPerPageOptions={[25, 30, 50, 100, 200, 500]}
                                onColumnOrderChange={(e) => {
                                    this.ColumnOrderChange(e)
                                }}
                                checkboxSelection={false}
                                headerHeight={70}
                                loading={this.props.TableLoading}
                                rowCount={this.props.rowCount}
                                paginationMode='server'
                                onPageChange={(page) => this.useQuery(page)}
                                onPageSizeChange={pageSize => this.changePageSize(pageSize)}
                                columnVisibilityModel={this.state.Visibility}
                                onColumnVisibilityModelChange={(newModel) =>
                                    this.ChangeColumnVisibilityModel(newModel)
                                }
                                disableMultipleSelection={false}
                                initialState={
                                    {pinnedColumns: {left: ['tracking_number'], right: ['actions']}}
                                }
                                rowReordering={false}

                                pagination={true}

                                sortingMode="server"
                                // sortModel={this.set_sort}
                                onSortModelChange={(newSortModel) => this.ChangeColumnSortModel(newSortModel)}

                                components={{
                                    LoadingOverlay: LinearProgress,
                                    NoRowsOverlay: CustomNoRowsOverlay,
                                    Toolbar: APIToolBar,
                                }}
                                columnBuffer={3}
                                columnThreshold={3}/> : ''}
                        </StyledBox>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.shipment.isLoading,
    TableLoading: state.shipment.TableLoading,
    ModalLoading: state.shipment.ModalLoading,
    pageSize: state.shipment.pageSize,
    page: state.shipment.page,
    rowCount: state.shipment.rowCount,
    Refresh: state.indexReducer.Refresh,
    data: state.shipment.data,
    search: state.shipment.search,
    searchClick: state.shipment.searchClick,
    orders: state.shipment.orders,
    orderClick: state.shipment.orderClick,
    alert: state.shipment.alert,
    payload: state.shipment.content,
    user: state.auth.user,
    with_holding: state.shipment.with_holding,
    refund: state.shipment.refund,
    lump_sum: state.shipment.lump_sum
});

export default connect(mapStateToProps, {loadShipment})(Shipment)